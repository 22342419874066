"use client";

import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { InputProps } from "./Input";
import { TextInput } from "./TextInput";

type InputType = "password" | "text";

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [passwordInputType, setPasswordInputType] =
      useState<InputType>("password");

    const togglePasswordVisibility = () => {
      setPasswordInputType((prevType) =>
        prevType === "password" ? "text" : "password"
      );
    };

    return (
      <TextInput
        {...props}
        ref={ref}
        type={passwordInputType}
        icon={
          <div
            className="cursor-pointer"
            onClick={() => togglePasswordVisibility()}
          >
            {passwordInputType === "password" ? <FaEyeSlash /> : <FaEye />}
          </div>
        }
      />
    );
  }
);
