"use client";
import classNames from "classnames";
import Link from "next/link";
import { CancelIcon } from "../icons";
import styles from "./Modal.module.scss";
import { useEffect } from "react";

export function Modal({
  returnHref,
  children,
  className,
  onClose,
}: {
  children: React.ReactNode;
  returnHref: string;
  className?: string;
  onClose?: () => void;
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    const mainElements = document.getElementsByTagName("main");
    if (mainElements && mainElements.length > 0) {
      mainElements[0].style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
      const mainElements = document.getElementsByTagName("main");
      if (mainElements && mainElements.length > 0) {
        mainElements[0].style.overflow = "auto";
      }
    };
  }, []);

  return (
    <div className={styles.Modal}>
      <div className={classNames("overflow-y-auto", className)}>
        <div>{children}</div>
        <Link href={returnHref} onClick={onClose} scroll={false}>
          <CancelIcon className="m-4 text-4xl stroke-white" />
        </Link>
      </div>
    </div>
  );
}
